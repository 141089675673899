import { breakpoints } from 'shared/breakpoints';
import { useState, useEffect } from 'react';
import { useCurrentWidth } from 'utils/hooks/useCurrentWidth';

export const useBreakpoint = (breakpoint: string) => {
    const [breakpointNumber, setBreakpointNumber] = useState(0);
    const currentWidth = useCurrentWidth();

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setBreakpointNumber(breakpoints.xs);
                break;
            case 'sm':
                setBreakpointNumber(breakpoints.sm);
                break;
            case 'md':
                setBreakpointNumber(breakpoints.md);
                break;
            case 'lg':
                setBreakpointNumber(breakpoints.lg);
                break;
        }
    }, [breakpoint]);

    const [isBreakpoint, setIsBreakpoint] = useState(false);

    useEffect(() => {
        if (currentWidth <= breakpointNumber) {
            setIsBreakpoint(true);
        } else {
            setIsBreakpoint(false);
        }
    }, [currentWidth, breakpointNumber]);
    return isBreakpoint;
};
